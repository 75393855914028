import React from 'react';
import { Tabs } from 'antd';

import { types } from '../config/config';
import '../css/TopMenu.css';

import MenuContent from './MenuContent';

const { TabPane } = Tabs;

function TopMenu(props) {
  const styles = {
    menuParent: {
      minHeight: 800
    },
    content: {
      textAlign: 'center'
    }
  };

  return (
    <Tabs
      type="card"
      defaultActiveKey="0"
      tabPosition={'top'}
      style={styles.menuParent}
      onChange={props.updateModule}
    >
      {
        types.map((type, i) => (
          <TabPane tab={type.name} key={i} style={styles.content}>
            <MenuContent
              type={type}
              currentContent={props.currentContent}
              saveFontSize={props.saveFontSize}
              fontSize={props.fontSize}
            />
          </TabPane>
        ))
      }
    </Tabs>
  )
}

export default TopMenu;