import React from 'react';
import ReactGA from 'react-ga';

const fetchContent = type => {
  let year = '';
  if (type.type === 'JingShenZhiBiao') {
    year = (new Date()).getFullYear();
  }
  return new Promise((resolve, reject) => {
    fetch(`/text/${type.type}${year}.txt`)
      .then((r) => r.text())
      .then(text  => {
        resolve(text);
      })
      .catch(err => {
        reject(err);
      });
  })
};

const formatContent = content => {
  let final = [];

  const styles = {
    oneline: {
      display: 'inline-block'
    },
    divider: {
      display: 'inline-block',
      width: 40
    },
    title: {
      fontSize: '1.3em',
      fontWeight: 'bold',
      marginBottom: 10
    }
  };

  let allLines = content.split('\n');

  if (allLines[0] === 'text') {
    for (let u=0; u < allLines.length; u++) {
      if (u === 0) {
        continue;
      }
      let tline = [];

      const t = allLines[u];

      if (u === 1) {
        tline.push(<div key={`u${u}`} style={styles.title}>{t}</div>);
        final.push(<div key={`utitle${u}`}>{tline}</div>);
        continue;
      }

      let split = t.split('::::');

      if (split < 2) {
        if (t.indexOf('****') > -1) {
          let mu = t.split('****');
          mu.map((m, p) => {
            if (p !== 0) {
              tline.push(<img key={`dmuu${p}`} src="/images/mu.png" alt="mu" style={{width: 30}} />);
            }
            tline.push(<div key={`dlineu${p}`} style={styles.oneline}>{m}</div>)
            return null;
          });
        } else {
          tline.push(<div key={`usplit${u}`} style={styles.oneline}>{t}</div>);
        }
      } else {
        split.map((d, i) => {
          if (i !== 0) {
            tline.push(<div key={`dd${i}`} style={styles.divider}> </div>);
          }
          if (d.indexOf('****') > -1) {
            let mu = d.split('****');
            mu.map((m, p) => {
              if (p !== 0) {
                tline.push(<img key={`dmu${p}`} src="/images/mu.png" alt="mu" style={{width: 30}} />);
              }
              tline.push(<div key={`dline${p}`} style={styles.oneline}>{m}</div>);
              return null;
            });
          } else {
            tline.push(<div key={`d${i}`} style={styles.oneline}>{d}</div>);
          }
          return null;
        });
      }

      final.push(<div key={u}>{tline}</div>);
    }

    return <div>{final}</div>;
  } else {
    return null;
  }
};

const gaEvent = data => {
  let ga = {};
  if (data) {
    if (data.action) {
      ga.category = 'click';
      ga.action = data.action;
    }
    if (data.category) {
      ga.category = data.category;
    }
    if (data.label) {
      let label = data.label;
      if (label.indexOf('@') > 0 && label.indexOf('.') > 0) {
        label = label.substring(0, label.lastIndexOf('.'));
        label = label.replace('@', '_');
      }
      ga.label = label;
    }
    if (data.value) {
      ga.value = data.value;
    }
  }

  ReactGA.event(ga);
};

const scrollToTop = duration => {
  // cancel if already on top
  if (document.scrollingElement.scrollTop === 0) return;

  const cosParameter = document.scrollingElement.scrollTop / 2;
  let scrollCount = 0, oldTimestamp = null;

  function step (newTimestamp) {
    if (oldTimestamp !== null) {
      // if duration is 0 scrollCount will be Infinity
      scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
      if (scrollCount >= Math.PI) return document.scrollingElement.scrollTop = 0;
      document.scrollingElement.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
};

export {
  gaEvent,
  fetchContent,
  formatContent,
  scrollToTop
}