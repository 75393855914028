const config = {
  google: {
    clientID: '609161053666-poifbmhqqkvqoh73d2e0sndikh5sqa9f.apps.googleusercontent.com',
    GAId: {
      local: 'UA-165427011-2',
      prod: 'UA-165427011-1'
    }
  },
  application: {
    url: {
      local: '//localhost:2000',
      prod: 'https://study.fycd.us'
    }
  }
};

const types = [
  { name: '彌勒救苦真經', type: 'MiLeJiuKuJing', video: 'W1kWH_LP4hQ', audio: '1ESq7rOpL2E4ucQeIaYLo-vss_Yr2DOB-' },
  { name: '號召令', media: 'video', type: 'haoZhaoLing480'},
  { name: '關聖帝君懺悔文', type: 'GuanShengDiJunCanHuiWen', video: '', audio: '' },
  { name: '道之宗旨', type: 'DaoZhiZhongZi', video: 'AH9A5O-j0LM', audio: '1mWPZDM8Xy37UhF543b2-K2GSyYy7-K__' },
  { name: '禮運大同篇', type: 'LiYunDaTongPian', video: '4n9Gs8Op9qg', audio: '1WhBeeVZdlIvZKcYQL0Ipk76Izz2lLAMS' },
  { name: '心經', type: 'XinJing', video: 'bYfPcWRU-ic', audio: '1Tu5z_lNU87tUFapkW4AFjmCa6qkHLsqZ' },
  { name: '清靜經', type: 'QingJingJing', video: 'OkWAHPYJFl0', audio: '15BlpkSLv0RKIh_kxhbfWM-2d9VD4mSRp' },
  { name: '參辭駕禮', type: 'CanCiJiaLi', video: '', audio: '' },
  { name: '辦道焚表詞', type: 'BanDaoFenBiaoChi', video: 'HvYmzgP4fVM', audio: '1l2fcNYGEnN_Cy_KD_RmwvmzU7dK85woC' },
  { name: '引保師愿詞', type: 'YingBaoShiYuanChi', video: 'bXt2P6cc5IA', audio: '1fKtywzoCkTCNCJGT3Ik2Qo0Bcxksoag9' },
  { name: '求道人愿詞', type: 'QiuDaoRenYuanChi', video: 'fs2SIHckeBg', audio: '1US1gYpvB5X-P61qqwEMXJdtbDLvULcxN' },
  { name: '十五條佛規', type: 'ShiWuTiaoFoGui', video: '2yh1uCwW2uU', audio: '1Gv0-gM0Q_gkN7Xt5g7IzNG1yo2G5I0qs' },
  { name: '忠義鼎', type: 'ZhongYiDing', video: 'tI9uUsMAGuQ', audio: '1Mmz3_CmektjNIb0Tty2Bha8Ch7F7g0cY' },
  { name: '忠義鼎訓中訓', type: 'ZhongYiDingXunZhongXun', video: 'jkyZ8vxsXG0', audio: '1Va1hn5F2L0CGghqJ789E14XNYpYx9b4b' },
  { name: '忠義精神', type: 'ZhongYiJingShen', video: '_JwT6rT51I4', audio: '1fKkGLwR8-oReNZDHE_mFsmg__J53Hrv7' },
  { name: '標準崇德人', type: 'BiaoJunChongDeRen', video: '', audio: '' },
  { name: '崇德道場', type: 'ChongDeDaoChang', video: '', audio: '' },
  { name: '萬世金鐘', type: 'WanShiJingZhong', video: 'U79WOwpAXfk', audio: '1y_nKEDXas2YqAiLSh6J5GXznog6jvqmw' },
  { name: '萬世金鐘訓中訓', type: 'WanShiJingZhongXunZhongXun', video: 'nc2-oP6FZfM', audio: '1D6DtaEvI6SNtGKcRq3HRRm68stKtmr78' },
  { name: '大學', type: 'DaXue', video: '', audio: '' },
  { name: '中庸', type: 'ZhongYong', video: '', audio: '' },
  { name: '三清四正', type: 'SanQingSiZheng', video: '', audio: '' },
  { name: '四好四真', type: 'SiHaoSiZhen', video: '', audio: '' },
  { name: '五度、修道四門課程', type: 'WuDuXiuDaoSiMenGongKe', video: '', audio: '' },
  { name: '神課', type: 'ShenKe', video: 'RcCd8lXCIEA', audio: '1XJHtvEzt80IEaQ3E9vutNydKkLetksXG' },
  { name: '品格功德', type: 'PinGeGongDe', video: 'G7Ty46sJPBc', audio: '1MYlsGmZdZKsXTFrQkSOz-c2WJaIsA99a' },
  { name: '火候', type: 'HuoHou', video: '63RwHs5SfCY', audio: '11LCC1V9QdGML-IFzKudALwKMPMwxeXQe' },
  { name: '禮節', type: 'LiJie', video: 'y0iAIqz9c8I', audio: '1edk665ECn0IuPUIcWwKzvBCl4M68ow7u' },
  { name: '心念', type: 'XinNian', video: '2zLLW9D55YU', audio: '1Y0KKLwAjM5Usi6Kiid4peIPRVCuIm7w2' },
  { name: '戒律', type: 'JieLv', video: 'hdoGF0Q07u8', audio: '1VV8_T2vURxdF20oM4rwnXH52ovfIOOli' },
  { name: '願行', type: 'YuanXing', video: 'HGH91gZOvW8', audio: '12BNeUUbMzEIjTp-vpX52QAfxaT514B-6' },
  { name: '七門功課', type: 'QiMenGongKe', video: 'txwV-vxNWmU', audio: '1IadAChyhRCE6W6w2sSXXFTnkV9-VPO62' },
  { name: '百孝經', type: 'BaiXiaoJing', video: '', audio: '' },
  { name: '精神指標', type: 'JingShenZhiBiao', video: '', audio: '' },
  { name: '祝禱文', type: 'ZhuDaoWen', video: 'HKIYnTVBb9I', audio: '1Nks_l6Qa9BpuNWxJVkMQqLCEGY1XElh6' },
  { name: '法王上座', type: 'FaWangShangZuo', video: 'C4sjQvrQgYE', audio: '1-pPSTy80HHySYVnYdDGYMvw9C0omEqP0' },
  { name: '九思', type: 'JiuSi', video: '40xgGcCrDLo', audio: '1VnNcIYdC7PuOb6d8AxUO6R55SAMUPcpL' },
  { name: '崇德六大傳承', type: 'ChongDeLiuDaChuanCheng', video: 'dgn9RPN1uXM', audio: '17h5u3tGqszAl8zj15XXaGdaM7N6mE2on' },
  { name: '三方針四維法', type: 'SanFangZhenSiWeiFa', video: 'CE7pmFjQ9ew', audio: '1KhFnqjEon5nGkbSYUak-6nXUb0ndGrXR' },
  { name: '中華歷史朝代', type: 'ZhongHuaLiShiChaoDai', video: '', audio: '' }
];

export {
  types,
  config
}