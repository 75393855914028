import React, { useState, useEffect } from 'react';

const PlayVideo = props => {
  const [ videoWidth, setVideoWidth ] = useState(480);

  useEffect(() => {
    let screenWidth = window.screen.width;
    if (screenWidth > 480) {
      screenWidth = 480;
    }
    setVideoWidth(screenWidth);
  }, []);


  const styles = {
    video: {
      marginTop: 10,
      marginBottom: 40,
      maxWidth: 480,
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  };

  const poster = `/images/${props.type.type}.png`;

  return (
    <div style={styles.video}>
      <video controls width={videoWidth} poster={poster}>
        <source src={`//studyaudio.fycd.us/video/${props.type.type}.webm`} type="video/webm" />
        <source src={`//studyaudio.fycd.us/video/${props.type.type}.mp4`} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  )
};

export default PlayVideo;